import React from "react";
import { LayoutGeneral } from "src/layout/LayoutGeneral";
import { RollbackOutlined } from "@ant-design/icons";
import { Link } from "gatsby";
import { Layout } from "antd";

import UrbanLab1 from "src/components/referencias/images/exterior/oficinas-coworking-urban-lab/thermoProtect-urban-lab1.jpg";
import UrbanLab2 from "src/components/referencias/images/exterior/oficinas-coworking-urban-lab/thermoProtect-urban-lab2.jpg";
import UrbanLab3 from "src/components/referencias/images/exterior/oficinas-coworking-urban-lab/thermoProtect-urban-lab3.jpg";

import { ThermoProtectImgAndLinks } from "src/components/referencias/exterior/ThermoProtect";

import { FacebookOutlined, InstagramOutlined } from "@ant-design/icons";

import { Image } from "antd";
import { List, Card } from "antd";
const { Header, Content } = Layout;

const data = [
  {
    src: UrbanLab1,
  },
  {
    src: UrbanLab2,
  },
  {
    src: UrbanLab3,
  },
];

const OficinasCoworkingUrbanLab = (props) => (
  <LayoutGeneral
    {...{
      ...props,
      showPageTitle: false,
      pageTitle: "ThermoProtect: Casa de Bertín Osborne en Tele5",
      pageName: "Referencia ThermoProtect",
      pageDescription:
        "ClimateCoating ThermoProtect: Usado en el la casa del programa En tu casa o en la mía de Bertín Osborne",
    }}
  >
    <Content className="thermoProtect referencia">
      <div className="linkBack referencia">
        <Link to="/referencias/exterior/">
          <RollbackOutlined />{" "}
          <span className="colored linkText">Ir a Referencias Exterior</span>
        </Link>
      </div>

      <div className="marginTop50">
        <ThermoProtectImgAndLinks />
        <h1 className="pageTitle referencia">
          <b className="thermoProtect">ClimateCoating ThermoProtect: </b>
          <span className="secondText">
            Oficinas y espacio coworking Urban Lab en Madrid
          </span>
        </h1>
        <p>
          El caso del edificio de oficinas de Urban Lab en Madrid supone un
          escenario límite para el revestemiento empleado en su fachada. El
          vanguardista diseño no podría llevarse a cabo si no se contase con un
          <strong className="grey">
            revestimiento capaz de lograr la estabilidad cromática requerida
          </strong>
          .
        </p>
        <br />
        <br />
      </div>
      <div className="clearer"></div>
      <Image.PreviewGroup preview={{}}>
        <List
          grid={{ gutter: 16, column: 3 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <Image src={item.src} />
            </List.Item>
          )}
        />
      </Image.PreviewGroup>
      <br />
      <br />
    </Content>
  </LayoutGeneral>
);

export default OficinasCoworkingUrbanLab;
