import React from "react";

import Slider from "react-slick";
import greenguardImg from "src/components/certificates/images/climateCoating-greenguard.png";
import energyStarImg from "src/components/certificates/images/climateCoating-energy-star.png";
import eficienciaEnergeticaAlemanaImg from "src/components/certificates/images/climateCoating-energie-effizienz.png";

import germanInnovationAwardImg from "src/components/certificates/images/climateCoating-german-innovation-award.png";

import certificadoAecIqnet from "src/components/certificates/images/climateCoating-iq-net.png";

export const CertificatesCarouselSecondary = ({
  float = "none",
  width = "400px",
  maxWidth = "98%",
  maxHeight = "100px",
  marginLeft = "auto",
  marginRight = "auto",
  slidesToShow = 3,
  marginTop = "50px",
  marginBottom = "50px",
}) => {
  var settings = {
    dots: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow,
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div
      className="carouselCertificates"
      style={{
        float,
        maxWidth,
        marginBottom,
        maxHeight,
        marginRight,
        marginLeft,
        marginTop,
        marginBottom,
      }}
    >
      <Slider {...settings}>
        <div key="greenGuard">
          <h3>
            <img src={greenguardImg} />
          </h3>
        </div>
        <div key="eficienciaEnergetica">
          <h3>
            <img src={eficienciaEnergeticaAlemanaImg} />
          </h3>
        </div>
        <div key="energyStar">
          <h3>
            <img src={energyStarImg} />
          </h3>
        </div>
        <div key="germanInnovation">
          <h3>
            <img src={germanInnovationAwardImg} />
          </h3>
        </div>
        <div key="aecIqNet">
          <h3>
            <img src={certificadoAecIqnet} />
          </h3>
        </div>
      </Slider>
    </div>
  );
};

export default CertificatesCarouselSecondary;
